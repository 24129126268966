body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast--default, .Toastify__toast--info, .Toastify__toast--success, 
.Toastify__toast--warning, .Toastify__toast--error{
  font-weight: 700;
}

.Toastify__toast-container {
  width: 380px;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #2296e3;
}
.Toastify__toast--success {
  background: #06950a;
}
.Toastify__toast--warning {
  background: #ecad19;
}
.Toastify__toast--error {
  background: #d41c08;
}

.Toastify__toast--error, .Toastify__toast--warning{
  white-space: pre-line;  
  line-height: 20px;
}

.iconNotification{
  margin-right: 5px;
}
